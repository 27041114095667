function getServiceType(specialty: string): string {
  switch (specialty) {
    case 'ACOMPANHAMENTO TÉCNICO':
      return 'Acompanhamento Técnico';
    case 'CALDEIRARIA':
      return 'Caldeiraria';
    case 'CONFECÇÃO':
      return 'Confecção';
    case 'DESATIVADO':
      return 'Desativado';
    case 'INSPEÇÃO NÃO SISTEMÁTICA':
      return 'Inspeção não Sistemática';
    case 'INSPEÇÃO SISTEMÁTICA':
      return 'Inspeção Sistemática';
    case 'LUBRIFICAÇÃO':
      return 'Lubrificação';
    case 'MANUTENÇÃO CORRETIVA':
      return 'Manutenção Corretiva';
    case 'MANUTENÇÃO CORRETIVA PROGRAMADA':
      return 'Manutenção Corretiva Programada';
    case 'MANUTENÇÃO PREDIAL':
      return 'Manutenção Predial';
    case 'MANUTENÇÃO PREDITIVA':
      return 'Manutenção Preditiva';
    case 'MANUTENÇÃO PREVENTIVA':
      return 'Manutenção Preventiva';
    case 'MELHORIA':
      return 'Melhoria';
    case 'ORGANIZAÇÃO E LIMPEZA - 5S':
      return 'Organização E Limpeza - 5s';
    default:
      return '';
  }
}

export default getServiceType;
