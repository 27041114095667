import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  Home,
  // Backlog,
  Programming,
  Planning,
  Report,
  Sag,
  Warehouse,
  Well,
} from '../pages';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    {/* <Route path="/backlog" component={Backlog} /> */}
    <Route path="/programming" component={Programming} />
    <Route path="/planning" component={Planning} />
    <Route path="/reports" component={Report} />
    <Route path="/sag" component={Sag} />
    <Route path="/well" component={Well} />
    <Route path="/warehouse" component={Warehouse} />
  </Switch>
);

export default Routes;
