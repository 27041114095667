import React from 'react';
import PropTypes from 'prop-types';

import { Card, Header } from './styles';

type CardDefaultProps = {
  title: string;
  children: React.ReactNode;
};

const CardDefault: React.FC<CardDefaultProps> = ({ title, children }) => (
  <Card>
    <Header as="h5">{title}</Header>
    <Card.Body>{children}</Card.Body>
  </Card>
);

CardDefault.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default CardDefault;
