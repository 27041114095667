import { Navbar as Navigation, Nav } from 'react-bootstrap';
import styled from 'styled-components';

import Colors from '../../../assets/styles/Colors';

export const Navbar = styled(Navigation)`
  background-color: #fff;
`;

export const Brand = styled(Navigation.Brand)``;

export const Toggle = styled(Navigation.Toggle)``;

export const Links = styled.div`
  background-color: ${Colors.primary};
`;

export const Link = styled(Nav.Link)`
  color: #727271;
`;

export const Collapse = styled(Navigation.Collapse)``;
