import styled from 'styled-components';
import {
  Row as RowTemplate,
  Col as ColTemplate,
  Tab as TabTemplate,
  Tabs as TabsTemplate,
} from 'react-bootstrap';

import {
  ChartDoughnutDefault,
  ChartBarDefault,
  ChartBarHorizontalDefault,
  ChartPie as ChartPieTemplate,
  ChartBarStackedDefault,
} from '../../components/Charts';
import CardDefault from '../../components/Cards/CardDefault';

import { TableDefault } from '../../components/Tables';

export const Row = styled(RowTemplate)`
  ${(props) => (!props.noMargin && `margin: 15px;`)}
  ${(props) => (props.marginTop && `margin-top: 15px;`)}
`;

export const Col = styled(ColTemplate)``;

export const Tab = styled(TabTemplate)``;

export const Tabs = styled(TabsTemplate)``;

export const ChartDoughnut = styled(ChartDoughnutDefault)``;

export const ChartBar = styled(ChartBarDefault)``;

export const Card = styled(CardDefault)``;

export const Table = styled(TableDefault)``;

export const ChartStackedBar = styled(ChartBarStackedDefault)``;

export const ChartPie = styled(ChartPieTemplate)``;

export const Title = styled.h1`
  font-size: 48px;
  color: #3a3a3a;
`;

export const Container = styled.div`
  text-align: center;
  margin: 20px;
`;

export const FileUpload = styled.div`
  margin: 30px;
`;

export const InputUpload = styled.input`
  padding: 15px;
  background-color: #0569c8;
  color: #dcdcdc;
  border-radius: 5px;

  &[type='file']::-webkit-file-upload-button {
    margin: 0;
  }
  &[type='file']::-webkit-file-upload-button {
    border: none;
    background: #0569c8;
    color: #fff;
  }
`;

export const Buttons = styled.div`
  margin: 20px;
`;

export const Li = styled.li`
  list-style-type: none;
`;
