import React, { useEffect, useState } from 'react';

import _ from 'underscore';

import * as xlsx from 'node-xlsx';

import {
  Row,
  Col,
  Title,
  Container,
  FileUpload,
  InputUpload,
  ChartDoughnut,
  ChartBar,
  ChartStackedBar,
  Card,
  Table,
  Tab,
  Tabs,
} from './styles';

import { HeaderMaintenance as Header } from '../../components/Headers';

import excelToData from './excelToData';

import { getCollaboratorSpecialty, toSeconds, minTommss } from '../../utils';

import {
  chartCountInterface,
  osWeekInterface,
  tableDataInterface,
  osInterface,
  collaboratorsInterface,
} from './interfaces';

const Programming: React.FC = () => {
  const [oss, setOss] = useState<osInterface[]>([]);
  const [collaborators, setCollaborators] = useState<collaboratorsInterface[]>(
    [],
  );
  const [totalHoursProgrammed, setTotalHoursProgrammed] = useState(0);
  const [dataOsWeekDay, setDataOsWeekDay] = useState<osWeekInterface[]>([]);
  const [dataOsShift, setDataOsShift] = useState<chartCountInterface[]>([]);
  const [dataOsArea, setDataOsArea] = useState<chartCountInterface[]>([]);
  const [dataOsSystematic, setDataOsSystematic] = useState<
    chartCountInterface[]
  >([]);
  const [dataOsSpeciality, setDataOsSpeciality] = useState<
    chartCountInterface[]
  >([]);

  const collaboratorsColum = [
    {
      dataField: 'name',
      text: 'Nome',
      sort: true,
    },
    {
      dataField: 'hours',
      text: 'H. Prog.',
      sort: true,
      formatter: (cell: number) => ` ${minTommss(cell)}h`,
    },
    {
      dataField: 'totalTasks',
      text: 'Total OS',
      sort: true,
    },
    {
      dataField: 'totalTasksSystematic',
      text: 'Sist.',
      sort: true,
    },
    {
      dataField: 'totalTasksNoSystematic',
      text: 'Ñ Sist.',
      sort: true,
    },
  ];

  const getOsByWeek = (): void => {
    const osByDay: osWeekInterface[] = [];

    oss.map(
      ({
        id,
        description,
        place,
        area,
        equipment,
        specialty,
        systematic,
        type,
        responsible,
        changedBy,
        actions,
      }) => {
        actions.map((action) => {
          const index = osByDay.findIndex(
            (eos: osWeekInterface) => eos.date === action.date,
          );

          if (index < 0) {
            osByDay.push({
              date: action.date,
              weekday: action.weekday,
              osSytematic: systematic ? 1 : 0,
              osNoSytematic: systematic ? 0 : 1,
              os: [
                {
                  id,
                  description,
                  place,
                  area,
                  equipment,
                  specialty,
                  systematic,
                  type,
                  responsible,
                  changedBy,
                  actions,
                  programming: [...action.programming],
                },
              ],
            });
          } else {
            systematic
              ? (osByDay[index].osSytematic += 1)
              : (osByDay[index].osNoSytematic += 1);

            osByDay[index].os.push({
              id,
              description,
              place,
              area,
              equipment,
              specialty,
              systematic,
              type,
              responsible,
              changedBy,
              actions,
              programming: [...action.programming],
            });
          }
        });
      },
    );

    return setDataOsWeekDay(
      osByDay.sort((a, b) => {
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) return -1;
        if (new Date(a.date).getTime() > new Date(b.date).getTime()) return 1;
        return 0;
      }),
    );
  };

  const getOsShift = (): void => {
    const count: chartCountInterface[] = [
      {
        name: 'Turno 1',
        total: 0,
      },
      {
        name: 'Turno 2',
        total: 0,
      },
      {
        name: 'Turno 3',
        total: 0,
      },
    ];

    dataOsWeekDay.map(({ os }) => {
      os.map(({ actions }) => {
        const hour = toSeconds(actions[0].programming[0].start.hour);

        const index =
          hour >= 20400 && hour < 46800
          ? 0
          : hour >= 46800 && hour < 78000
            ? 1
            : 2;

        count[index].total += 1;
      });
    });

    return setDataOsShift(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      }),
    );
  };

  const getCollaborators = (): void => {
    const collaboratorsList: collaboratorsInterface[] = [];

    oss.map(
      ({
        id,
        description,
        place,
        area,
        equipment,
        specialty,
        systematic,
        type,
        actions,
      }) => {
        actions.map(({ programming }) => {
          programming.map(({
 technician, duration, start, done
}) => {
            const index = collaboratorsList.findIndex(
              (eos: collaboratorsInterface) => eos.name === technician,
            );

            if (index < 0) {
              collaboratorsList.push({
                name: technician,
                specialty: getCollaboratorSpecialty(technician),
                hours: duration,
                totalTasks: 1,
                totalTasksSystematic: systematic ? 1 : 0,
                totalTasksNoSystematic: systematic ? 0 : 1,
                tasks: [
                  {
                    id,
                    description,
                    place,
                    area,
                    equipment,
                    specialty,
                    systematic,
                    type,
                    start_date: start.date,
                    start_hour: start.hour,
                    done_date: done.date,
                    done_hour: done.hour,
                  },
                ],
              });
            } else {
              collaboratorsList[index].hours += duration;

              collaboratorsList[index].totalTasks += 1;

              systematic
                ? (collaboratorsList[index].totalTasksSystematic += 1)
                : (collaboratorsList[index].totalTasksNoSystematic += 1);

              collaboratorsList[index].tasks.push({
                id,
                description,
                place,
                area,
                equipment,
                specialty,
                systematic,
                type,
                start_date: start.date,
                start_hour: start.hour,
                done_date: done.date,
                done_hour: done.hour,
              });
            }
          });
        });
      },
    );

    return setCollaborators(collaboratorsList);
  };

  const getOsSpecialty = (): void => {
    const count: chartCountInterface[] = [];

    oss.map(({ specialty }) => {
      const index = count.findIndex(
        (eos: chartCountInterface) => eos.name === specialty,
      );

      if (index < 0) {
        count.push({
          name: specialty,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    return setDataOsSpeciality(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      }),
    );
  };

  const getAreaCount = (): void => {
    const count: chartCountInterface[] = [];

    oss.map(({ area }) => {
      const index = count.findIndex(
        (eos: chartCountInterface) => eos.name === area,
      );

      if (index < 0) {
        count.push({
          name: area,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    return setDataOsArea(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      }),
    );
  };

  const getSystematicCount = (): void => {
    const count: chartCountInterface[] = [];

    oss.map(({ systematic }) => {
      const index = count.findIndex(
        (obj: chartCountInterface) => obj.name === (systematic ? 'Sistemática' : 'Não Sistemática'),
      );

      if (index < 0) {
        count.push({
          name: systematic ? 'Sistemática' : 'Não Sistemática',
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    return setDataOsSystematic(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      }),
    );
  };

  const getTotalTime = (): void => {
    const total = oss.reduce(
      (t, { hh: { programming } }) => t + programming,
      0,
    );

    return setTotalHoursProgrammed(total);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.files;

    if (newValue && newValue?.length >= 0) {
      const reader: FileReader = new FileReader();

      console.log(reader);

      reader.onload = (): void => {
        if (reader && reader.result) {
          const arrayBuffer: string | ArrayBuffer = reader.result;

          const source = xlsx.parse(arrayBuffer, {
            type: 'array',
            cellDates: true,
            cellNF: false,
            cellText: false,
          });

          const database = source[0].data;

          database.shift();

          return setOss(excelToData(database));
        }
      };

      if (!newValue.length) {
        setCollaborators([]);
        setOss([]);

        return;
      }

      reader.readAsArrayBuffer(newValue[0]);
    }
  };

  useEffect(() => {
    if (oss.length > 0) {
      getTotalTime();
      getAreaCount();
      getSystematicCount();
      getOsByWeek();
      getCollaborators();
      getOsSpecialty();
    }
  }, [oss]);

  useEffect(() => {
    if (dataOsWeekDay.length > 0) {
      getOsShift();
    }
  }, [dataOsWeekDay]);

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col sm="12" md="12">
            <Title>Programação</Title>
            <FileUpload>
              <InputUpload
                type="file"
                placeholder="Upload"
                accept=".xlsx"
                onChange={onChange}
              />
            </FileUpload>
          </Col>
        </Row>

        {oss.length !== 0 && (
          <>
            <Row>
              <Col sm="12" md="3">
                <Card title="H.h. Programadas">
                  <h1>{Math.round(totalHoursProgrammed)}H.h</h1>
                  <p>
                    {Math.round(totalHoursProgrammed / dataOsWeekDay.length)}
                    H.h/Dia
                  </p>
                  <p>{dataOsWeekDay.length}Dias</p>
                </Card>
              </Col>
              <Col sm="12" md="3">
                <ChartDoughnut
                  title="OS X Turno"
                  labels={_.pluck(dataOsShift, 'name')}
                  data={_.pluck(dataOsShift, 'total')}
                />
              </Col>
              <Col sm="12" md="3">
                <ChartBar
                  dataLabel="Total"
                  title="Sistemática X Não Sistemática"
                  labels={_.pluck(dataOsSystematic, 'name')}
                  data={_.pluck(dataOsSystematic, 'total')}
                />
              </Col>
              <Col sm="12" md="3">
                <ChartDoughnut
                  title="OS X Especialidade"
                  labels={_.pluck(dataOsSpeciality, 'name')}
                  data={_.pluck(dataOsSpeciality, 'total')}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="6">
                <Row>
                  <Col sm="12" md="12">
                    <ChartBar
                      dataLabel="Total"
                      title="OS x Área"
                      labels={_.pluck(dataOsArea, 'name')}
                      data={_.pluck(dataOsArea, 'total')}
                    />
                  </Col>
                </Row>
                <Row>
                <Col sm="12" mda="12">
                <ChartStackedBar
                  title="Os x Dia"
                  labels={_.pluck(dataOsWeekDay, 'date')}
                  data={[
                    {
                      label: 'Sistemática',
                      data: _.pluck(dataOsWeekDay, 'osSytematic'),
                    },
                    {
                      label: 'Não Sistemática',
                      data: _.pluck(dataOsWeekDay, 'osNoSytematic'),
                    },
                  ]}
                />
              </Col>
                </Row>
              </Col>
              <Col sm="12" md="6">
                <Card title="Mão de Obra">
                  <Tabs
                    defaultActiveKey="electrical"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="electrical" title="Elétrica">
                      <Table
                        columns={collaboratorsColum}
                        data={collaborators.filter(
                          ({ specialty }) => specialty === 'Elétrica',
                        )}
                      />
                    </Tab>
                    <Tab eventKey="mechanics" title="Mecânica">
                      <Table
                        columns={collaboratorsColum}
                        data={collaborators.filter(
                          ({ specialty }) => specialty === 'Mecânica',
                        )}
                      />
                    </Tab>
                    <Tab eventKey="outsourced" title="Terceirizados">
                      <Table
                        columns={collaboratorsColum}
                        data={collaborators.filter(
                          ({ specialty }) => specialty === 'Terceirizados',
                        )}
                      />
                    </Tab>
                  </Tabs>
                </Card>
              </Col>
            </Row>


            {/* <Table c8olumns={tableColum} data={tableData} filter="name" /> */}

            {console.log({ oss, dataOsWeekDay, collaborators })}
          </>
        )}
      </Container>
    </>
  );
};

export default Programming;
