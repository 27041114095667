import React, { useEffect, useState } from 'react';

import _ from 'underscore';

import * as xlsx from 'node-xlsx';

import {
  Row,
  Col,
  Title,
  Container,
  FileUpload,
  InputUpload,
  ChartDoughnut,
  ChartPie,
  ChartBar,
  ChartStackedBar,
  Card,
  Table,
  Tab,
  Tabs,
} from './styles';

import { HeaderMaintenance as Header } from '../../components/Headers';

import excelToData from './excelToData';

import { getCollaboratorSpecialty, toSeconds, minTommss } from '../../utils';

import {
  chartCountInterface,
  osWeekInterface,
  osExternalInterface,
  tableDataInterface,
  osInterface,
  collaboratorsInterface,
} from './interfaces';

const Programming: React.FC = () => {
  const [oss, setOss] = useState<osInterface[]>([]);
  const [osExternals, setOsExternals] = useState<osExternalInterface[]>([]);
  const [collaborators, setCollaborators] = useState<collaboratorsInterface[]>(
    [],
  );
  const [totalHoursProgrammed, setTotalHoursProgrammed] = useState(0);
  const [dataOsWeekDay, setDataOsWeekDay] = useState<osWeekInterface[]>([]);
  const [dataOsShift, setDataOsShift] = useState<chartCountInterface[]>([]);
  const [dataOsArea, setDataOsArea] = useState<chartCountInterface[]>([]);
  const [dataOsType, setDataOsType] = useState<chartCountInterface[]>([]);
  const [dataOsComplianceSchedule, setDataOsComplianceSchedule] = useState<chartCountInterface[]>([]);
  const [dataOsMaintenanceAdherence, setDataOsMaintenanceAdherence] = useState<chartCountInterface[]>([]);
  const [dataOsTopEquipmentCorrective, setDataOsTopEquipmentCorrective] = useState<chartCountInterface[]>([]);
  const [dataOsStatus, setDataOsStatus] = useState<chartCountInterface[]>([]);
  const [dataOsSystematic, setDataOsSystematic] = useState<
    chartCountInterface[]
  >([]);
  const [dataOsSpeciality, setDataOsSpeciality] = useState<
    chartCountInterface[]
  >([]);

  const collaboratorsColum = [
    {
      dataField: 'name',
      text: 'Nome',
      sort: true,
    },
    {
      dataField: 'hours',
      text: 'H. Prog.',
      sort: true,
      formatter: (cell: { prog:number }) => ` ${minTommss(cell.prog)}h`,
    },
    {
      dataField: 'hours',
      text: 'Índice de Prog.',
      sort: true,
      formatter: (cell: { prog:number, exec: number }) => `${Math.floor((cell.exec/cell.prog) * 100)}%`,
    },
    {
      dataField: 'hours',
      text: 'Índice de Plan.',
      sort: true,
      formatter: (cell: { prog:number, disp: number }) => `${Math.floor((cell.prog/cell.disp) * 100)}%`,
    },
    {
      dataField: 'totalTasks',
      text: 'Total OS',
      sort: true,
    },
    {
      dataField: 'totalTasksSystematic',
      text: 'Sistemáticas',
      sort: true,
    },
    {
      dataField: 'totalTasksNoSystematic',
      text: 'Ñ Sistemáticas',
      sort: true,
    },
  ];

  const getOsByWeek = (): void => {
    const osByDay: osWeekInterface[] = [];

    oss.map(
      ({
        id,
        description,
        place,
        area,
        equipment,
        specialty,
        systematic,
        type,
        responsible,
        changedBy,
        actions,
      }) => {
        actions.map((action) => {
          const index = osByDay.findIndex(
            (eos: osWeekInterface) => eos.date === action.date,
          );

          if (index < 0) {
            osByDay.push({
              date: action.date,
              weekday: action.weekday,
              osSytematic: systematic ? 1 : 0,
              osNoSytematic: systematic ? 0 : 1,
              os: [
                {
                  id,
                  description,
                  place,
                  area,
                  equipment,
                  specialty,
                  systematic,
                  type,
                  responsible,
                  changedBy,
                  actions,
                  programming: [...action.programming],
                },
              ],
            });
          } else {
            systematic
              ? (osByDay[index].osSytematic += 1)
              : (osByDay[index].osNoSytematic += 1);

            osByDay[index].os.push({
              id,
              description,
              place,
              area,
              equipment,
              specialty,
              systematic,
              type,
              responsible,
              changedBy,
              actions,
              programming: [...action.programming],
            });
          }
        });
      },
    );

    return setDataOsWeekDay(
      osByDay.sort((a, b) => {
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) return -1;
        if (new Date(a.date).getTime() > new Date(b.date).getTime()) return 1;
        return 0;
      }),
    );
  };

  const getOsShift = (): void => {
    const count: chartCountInterface[] = [
      {
        name: 'Turno 1',
        total: 0,
      },
      {
        name: 'Turno 2',
        total: 0,
      },
      {
        name: 'Turno 3',
        total: 0,
      },
    ];

    dataOsWeekDay.map(({ os }) => {
      os.map(({ actions }) => {
        const hour = toSeconds(
          new Date(actions[0].programming[0].start).toLocaleTimeString('it-IT'),
        );

        const index =
          hour >= 20400 && hour < 46800
          ? 0
          : hour >= 46800 && hour < 78000
            ? 1
            : 2;

        count[index].total += 1;
      });
    });

    return setDataOsShift(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      }),
    );
  };

  const getCollaborators = (): void => {
    const collaboratorsList: collaboratorsInterface[] = [];

    oss.map(
      ({
        id,
        description,
        place,
        area,
        equipment,
        specialty,
        systematic,
        type,
        actions,
      }) => {
        actions.map(({ programming, appointment }) => {

          programming.map(({ technician, duration, start, done }) => {
            const index = collaboratorsList.findIndex(
              (eos: collaboratorsInterface) => eos.name === technician,
            );

            if (index < 0) {
              collaboratorsList.push({
                name: technician,
                specialty: getCollaboratorSpecialty(technician),
                hours:{
                  prog: duration,
                  exec: 0,
                  disp: 0,
                },
                totalTasks: 1,
                totalTasksSystematic: systematic ? 1 : 0,
                totalTasksNoSystematic: systematic ? 0 : 1,
                tasks: [
                  {
                    id,
                    description,
                    place,
                    area,
                    equipment,
                    specialty,
                    systematic,
                    type,
                    start,
                    done,
                  },
                ],
              });
            } else {
              collaboratorsList[index].hours.prog += duration;

              collaboratorsList[index].totalTasks += 1;

              systematic
                ? (collaboratorsList[index].totalTasksSystematic += 1)
                : (collaboratorsList[index].totalTasksNoSystematic += 1);

              collaboratorsList[index].tasks.push({
                id,
                description,
                place,
                area,
                equipment,
                specialty,
                systematic,
                type,
                start,
                done,
              });
            }
          });

          appointment.map(({ technician, duration, start, done }) => {
            const index = collaboratorsList.findIndex(
              (eos: collaboratorsInterface) => eos.name === technician,
            );

            if (index < 0) {
              // collaboratorsList.push({
              //   name: technician,
              //   specialty: getCollaboratorSpecialty(technician),
              //   hours:{
              //     prog: duration,
              //     exec: 0,
              //   },
              //   totalTasks: 1,
              //   totalTasksSystematic: systematic ? 1 : 0,
              //   totalTasksNoSystematic: systematic ? 0 : 1,
              // });
            } else {
              collaboratorsList[index].hours.exec += duration;

              // collaboratorsList[index].totalTasks += 1;

              // systematic
              //   ? (collaboratorsList[index].totalTasksSystematic += 1)
              //   : (collaboratorsList[index].totalTasksNoSystematic += 1);

              // collaboratorsList[index].tasks.push({
              //   id,
              //   description,
              //   place,
              //   area,
              //   equipment,
              //   specialty,
              //   systematic,
              //   type,
              //   start,
              //   done,
              // });
            }
          });
        });
      },
    );

    const newCollaboratorsList = collaboratorsList.map(collaborator => {
      const dayTasks: Array<string> = [];

      collaborator.tasks.map(({start}) => {
        new Date(start).toLocaleDateString('pt-br') && dayTasks.push(new Date(start).toLocaleDateString('pt-br'));
      })

      collaborator.hours.disp = dayTasks.length * 7.33;

      return collaborator
    })

    return setCollaborators(newCollaboratorsList);
  };

  const getOsType = (): void => {
    const count: chartCountInterface[] = [];

    oss.map(({ type }) => {
      const index = count.findIndex(
        (eos: chartCountInterface) => eos.name === type,
      );

      if (index < 0) {
        count.push({
          name: type,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    osExternals.map(({ type }) => {
      const index = count.findIndex(
        (eos: chartCountInterface) => eos.name === type,
      );

      if (index < 0) {
        count.push({
          name: type,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    return setDataOsType(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      }),
    );
  };

  const getOsSpecialty = (): void => {
    const count: chartCountInterface[] = [];

    oss.map(({ specialty }) => {
      const index = count.findIndex(
        (eos: chartCountInterface) => eos.name === specialty,
      );

      if (index < 0) {
        count.push({
          name: specialty,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    osExternals.map(({ specialty }) => {
      const index = count.findIndex(
        (eos: chartCountInterface) => eos.name === specialty,
      );

      if (index < 0) {
        count.push({
          name: specialty,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    return setDataOsSpeciality(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      }),
    );
  };

  const getOsStatus = (): void => {
    const status = [
      {
        name: 'Ok',
        total: 0,
      },
      {
        name: 'Não',
        total: 0,
      },
      {
        name: 'Parcial',
        total: 0,
      },
    ];

    osExternals.map(({ actions }) => actions.map(
        ({ date_appointment_end, date_appointment_start, date_closing }) => {
          if (date_appointment_end && date_appointment_start && date_closing) {
            return (status[0].total += 1);
          }
          if (date_appointment_end && date_appointment_start && !date_closing) {
            return (status[2].total += 1);
          }
          return (status[1].total += 1);
        },
      ));

    // oss.map(({ actions }) => actions.map(
      // ({ date_appointment_end, date_appointment_start, date_closing }) => {
      //   if (date_appointment_end && date_appointment_start && date_closing) {
      //     return (status[0].total += 1);
      //   }
      //   if (date_appointment_end && date_appointment_start && !date_closing) {
      //     return (status[2].total += 1);
      //   }
      //   return (status[1].total += 1);
      // },
    // ));

    return setDataOsStatus(status);
  };

  const getAreaCount = (): void => {
    const count: chartCountInterface[] = [];

    oss.map(({ area }) => {
      const index = count.findIndex(
        (eos: chartCountInterface) => eos.name === area,
      );

      if (index < 0) {
        count.push({
          name: area,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    osExternals.map(({ area }) => {
      const index = count.findIndex(
        (eos: chartCountInterface) => eos.name === area,
      );

      if (index < 0) {
        count.push({
          name: area,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    return setDataOsArea(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      }),
    );
  };

  const getSystematicCount = (): void => {
    const count: chartCountInterface[] = [];

    oss.map(({ systematic }) => {
      const index = count.findIndex(
        (obj: chartCountInterface) => obj.name === (systematic ? 'Sistemática' : 'Não Sistemática'),
      );

      if (index < 0) {
        count.push({
          name: systematic ? 'Sistemática' : 'Não Sistemática',
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    osExternals.map(({ systematic }) => {
      const index = count.findIndex(
        (obj: chartCountInterface) => obj.name === (systematic ? 'Sistemática' : 'Não Sistemática'),
      );

      if (index < 0) {
        count.push({
          name: systematic ? 'Sistemática' : 'Não Sistemática',
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    return setDataOsSystematic(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      }),
    );
  };

  const getTotalTime = (): void => {
    const total = oss.reduce(
      (t, { hh: { programming } }) => programming && t + programming,
      0,
    );

    return setTotalHoursProgrammed(total);
  };

  const getComplianceSchedule = (): void => {
    const countExec: chartCountInterface[] = [];
    const countProg: chartCountInterface[] = [];

    oss.map(({ systematic , actions}) => {
      actions.map(({date_done})=>{
          const index = countProg.findIndex(
            (obj: chartCountInterface) => obj.name === (systematic ? 'Sistemática' : 'Não Sistemática'),
          );

          if (index < 0) {
            countProg.push({
              name: systematic ? 'Sistemática' : 'Não Sistemática',
              total: 1,
            });
          } else {
            countProg[index].total += 1;
          }
          if(date_done) {
            const index = countExec.findIndex(
              (obj: chartCountInterface) => obj.name === (systematic ? 'Sistemática' : 'Não Sistemática'),
            );

            if (index < 0) {
              countExec.push({
                name: systematic ? 'Sistemática' : 'Não Sistemática',
                total: 1,
              });
            } else {
              countExec[index].total += 1;
            }
          }
      })
    });

    osExternals.map(({ systematic, actions}) => {
      actions.map(({ date_closing })=> {
        if(date_closing) {
          const index = countExec.findIndex(
            (obj: chartCountInterface) => obj.name === (systematic ? 'Sistemática' : 'Não Sistemática'),
          );

          if (index < 0) {
            countExec.push({
              name: systematic ? 'Sistemática' : 'Não Sistemática',
              total: 1,
            });
          } else {
            countExec[index].total += 1;
          }
        }
      })
    });

    const countProgIndexS = countProg.findIndex(
      (obj: chartCountInterface) => obj.name === 'Sistemática',
    );

    const countProgIndexNS = countProg.findIndex(
      (obj: chartCountInterface) => obj.name === 'Não Sistemática',
    );

    const countExcIndexS = countExec.findIndex(
      (obj: chartCountInterface) => obj.name === 'Sistemática',
    );

    const countExcIndexNS = countExec.findIndex(
      (obj: chartCountInterface) => obj.name === 'Não Sistemática',
    );



    return setDataOsComplianceSchedule(
      [
        {
          name: 'Sistemática',
          total: countExec[countExcIndexS].total/countProg[countProgIndexS].total,
        },
        {
          name: 'Não Sistemática',
          total: countExec[countExcIndexNS].total/countProg[countProgIndexNS].total,
        }
      ])
  }

  const getMaintenanceAdherence = (): void => {
    const countExec: chartCountInterface[] = [];
    const countProg: chartCountInterface[] = [];

    oss.map(({ specialty , actions}) => {
      actions.map(({date_done})=>{
          const index = countProg.findIndex((obj: chartCountInterface) => obj.name === specialty);

          if (index < 0) {
            countProg.push({
              name: specialty ,
              total: 1,
            });
          } else {
            countProg[index].total += 1;
          }

          if(date_done) {
            const index = countExec.findIndex((obj: chartCountInterface) => obj.name === specialty)

            if (index < 0) {
              countExec.push({
                name: specialty,
                total: 1,
              });
            } else {
              countExec[index].total += 1;
            }
          }
      })
    });

    osExternals.map(({ specialty, actions}) => {
      actions.map(({ date_closing }) => {
        if(date_closing) {
          const index = countExec.findIndex((obj: chartCountInterface) => obj.name === specialty);

          if (index < 0) {
            countExec.push({
              name: specialty,
              total: 1,
            });
          } else {
            countExec[index].total += 1;
          }
        }
      })
    });

    const countProgIndexElectric = countProg.findIndex(
      (obj: chartCountInterface) => obj.name === 'Elétrica',
    );

    const countExecIndexElectric = countExec.findIndex(
      (obj: chartCountInterface) => obj.name === 'Elétrica',
    );

    const countProgIndexAutomation = countProg.findIndex(
      (obj: chartCountInterface) => obj.name === 'Automação',
    );

    const countExecIndexAutomation = countExec.findIndex(
      (obj: chartCountInterface) => obj.name === 'Automação',
    );

    const countProgIndexCooling = countProg.findIndex(
      (obj: chartCountInterface) => obj.name === 'Refrigeração',
    );

    const countExecIndexCooling = countExec.findIndex(
      (obj: chartCountInterface) => obj.name === 'Refrigeração',
    );

    const countProgIndexMechanics = countProg.findIndex(
      (obj: chartCountInterface) => obj.name === 'Mecânica',
    );

    const countExecIndexMechanics = countExec.findIndex(
      (obj: chartCountInterface) => obj.name === 'Mecânica',
    );

    const countProgIndexLubrication = countProg.findIndex(
      (obj: chartCountInterface) => obj.name === 'Lubrificação',
    );

    const countExecIndexLubrication = countExec.findIndex(
      (obj: chartCountInterface) => obj.name === 'Lubrificação',
    );

    const countProgIndexBoilermaking = countProg.findIndex(
      (obj: chartCountInterface) => obj.name === 'Unsinagem/Calderaria',
    );

    const countExecIndexBoilermaking = countExec.findIndex(
      (obj: chartCountInterface) => obj.name === 'Unsinagem/Calderaria',
    );

    const countProgIndexUtilities = countProg.findIndex(
      (obj: chartCountInterface) => obj.name === 'Utilidades',
    );

    const countExecIndexUtilities = countExec.findIndex(
      (obj: chartCountInterface) => obj.name === 'Utilidades',
    );

    const countProgIndexOutsourced = countProg.findIndex(
      (obj: chartCountInterface) => obj.name === 'Terceirizados',
    );

    const countExecIndexOutsourced = countExec.findIndex(
      (obj: chartCountInterface) => obj.name === 'Terceirizados',
    );

    const countProgIndexOthers = countProg.findIndex(
      (obj: chartCountInterface) => obj.name === 'Outros',
    );

    const countExecIndexOthers = countExec.findIndex(
      (obj: chartCountInterface) => obj.name === 'Outros',
    );


    return setDataOsMaintenanceAdherence(
      [
        {
          name: 'Elétrica',
          total: countExecIndexElectric >= 0 ? countExec[countExecIndexElectric].total : 1/countProgIndexElectric >= 0 ? countProg[countProgIndexElectric].total : 1,
        },
        {
          name: 'Automação',
          total: countExecIndexAutomation >= 0 ? countExec[countExecIndexAutomation].total : 1/countProgIndexAutomation >= 0 ?  countProg[countProgIndexAutomation].total: 1,
        },
        {
          name: 'Refrigeração',
          total: countExecIndexCooling >= 0 ? countExec[countExecIndexCooling].total : 1/ countProgIndexCooling >= 0 ? countProg[countProgIndexCooling].total: 1,
        },
        {
          name: 'Mecânica',
          total:  countExecIndexMechanics>= 0 ? countExec[countExecIndexMechanics].total: 1/countProgIndexMechanics  >= 0 ?countProg[countProgIndexMechanics].total: 1,
        },
        {
          name: 'Lubrificação',
          total: countExecIndexLubrication >= 0 ? countExec[countExecIndexLubrication].total: 1/countProgIndexLubrication >= 0 ?countProg[countProgIndexLubrication].total: 1,
        },
        {
          name: 'Unsinagem/Calderaria',
          total: countExecIndexBoilermaking >= 0 ? countExec[countExecIndexBoilermaking].total: 1/countProgIndexBoilermaking >= 0 ?countProg[countProgIndexBoilermaking].total: 1,
        },
        {
          name: 'Utilidades',
          total: countExecIndexUtilities >= 0 ? countExec[countExecIndexUtilities].total: 1/countProgIndexUtilities >= 0 ? countProg[countProgIndexUtilities].total: 1,
        },
        {
          name: 'Terceirizados',
          total: countExecIndexOutsourced >= 0 ? countExec[countExecIndexOutsourced].total: 1/countProgIndexOutsourced >= 0 ?countProg[countProgIndexOutsourced].total: 1,
        },
        {
          name: 'Outros',
          total: countExecIndexOthers >= 0 ? countExec[countExecIndexOthers].total : 1/countProgIndexOthers >= 0 ?countProg[countProgIndexOthers].total: 1,
        },
      ])
  }

  const getTopEquipmentCorrective = (): void => {

    const count: chartCountInterface[] = [];

    oss.map(({ equipment, type }) => {
        if (type === 'Manutenção Corretiva' || type === 'Manutenção Corretiva Programada' ) {

        const index = count.findIndex(
          (eos: chartCountInterface) => eos.name === equipment,
        );

        if (index < 0) {
          count.push({
            name: equipment,
            total: 1,
          });
        } else {
          count[index].total += 1;
        }
      }
    });

    osExternals.map(({ equipment, type }) => {
      if (type ==='Manutenção Corretiva' || type === 'Manutenção Corretiva Programada' ) {
        const index = count.findIndex(
          (eos: chartCountInterface) => eos.name === equipment,
        );

        if (index < 0) {
          count.push({
            name: equipment,
            total: 1,
          });
        } else {
          count[index].total += 1;
        }
      }
    });

    const orderCount =  count.sort((a, b) => {
      if (a.total > b.total) return -1;
      if (a.total < b.total) return 1;
      return 0;
    });

    return setDataOsTopEquipmentCorrective(
      orderCount.slice(0, 15)
    );
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.files;

    if (newValue && newValue?.length >= 0) {
      const reader: FileReader = new FileReader();

      reader.onload = (): void => {
        if (reader && reader.result) {
          const arrayBuffer: string | ArrayBuffer = reader.result;

          const source = xlsx.parse(arrayBuffer, {
            type: 'array',
            cellDates: true,
            cellNF: false,
            cellText: false,
          });

          const database = source[0].data;

          database.shift();
          const { orderServicesProg, orderServicesExternal } = excelToData(
            database,
          );

          console.log({
            orderServicesProg,
            orderServicesExternal,
          });

          setOsExternals(orderServicesExternal);

          return setOss(orderServicesProg);
        }
      };

      if (!newValue.length) {
        setCollaborators([]);
        setOss([]);

        return;
      }

      reader.readAsArrayBuffer(newValue[0]);
    }
  };

  useEffect(() => {
    if (oss.length > 0) {
      getTotalTime();
      getAreaCount();
      getSystematicCount();
      getOsByWeek();
      getCollaborators();
      getOsSpecialty();
      getOsType();
      getOsStatus();
      getComplianceSchedule();
      getMaintenanceAdherence();
      getTopEquipmentCorrective();
    }
  }, [oss]);

  useEffect(() => {
    if (dataOsWeekDay.length > 0) {
      getOsShift();
    }
  }, [dataOsWeekDay]);

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col sm="12" md="12">
            <Title>Relatório</Title>
            <FileUpload>
              <InputUpload
                type="file"
                placeholder="Upload"
                accept=".xlsx"
                onChange={onChange}
              />
            </FileUpload>
          </Col>
        </Row>

        {oss.length !== 0 && (
          <>
            <Row>
              <Col sm="12" md="6">
                <Row noMargin>
                  <Col sm="12" md="6">
                    <ChartDoughnut
                      title="OS Status"
                      labels={_.pluck(dataOsStatus, 'name')}
                      data={_.pluck(dataOsStatus, 'total')}
                    />
                  </Col>
                  <Col sm="12" md="6">
                    <ChartBar
                      dataLabel="Total"
                      title="Sistemática X Não Sistemática"
                      labels={_.pluck(dataOsSystematic, 'name')}
                      data={_.pluck(dataOsSystematic, 'total')}
                    />
                  </Col>
                </Row>
                <Row noMargin marginTop>
                <Col sm="12" md="6">
                  <ChartDoughnut
                    title="OS X Turno"
                    labels={_.pluck(dataOsShift, 'name')}
                    data={_.pluck(dataOsShift, 'total')}
                  />
                </Col>
                <Col sm="12" md="6">
                  <ChartBar
                    dataLabel="Total"
                    title="Cumprimento Programação"
                    labels={_.pluck(dataOsComplianceSchedule, 'name')}
                    data={_.pluck(dataOsComplianceSchedule, 'total')}
                  />
                </Col>
                  </Row>
              </Col>
              <Col sm="12" md="6">
                <ChartBar
                  dataLabel="Total"
                  title="Top Equipamento - Corretiva"
                  labels={_.pluck(dataOsTopEquipmentCorrective, 'name')}
                  data={_.pluck(dataOsTopEquipmentCorrective, 'total')}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="6">
                <ChartBar
                  dataLabel="Total"
                  title="OS x Tipo"
                  labels={_.pluck(dataOsType, 'name')}
                  data={_.pluck(dataOsType, 'total')}
                />
              </Col>
              <Col sm="12" md="6">
                <ChartBar
                  dataLabel="Total"
                  title="Aderência ao planos de manutenção"
                  labels={_.pluck(dataOsMaintenanceAdherence, 'name')}
                  data={_.pluck(dataOsMaintenanceAdherence, 'total')}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="6">
                <ChartPie
                  title="OS X Especialidade"
                  labels={_.pluck(dataOsSpeciality, 'name')}
                  data={_.pluck(dataOsSpeciality, 'total')}
                />
              </Col>
              <Col sm="12" md="6">
                <ChartBar
                  dataLabel="Total"
                  title="OS x Área"
                  labels={_.pluck(dataOsArea, 'name')}
                  data={_.pluck(dataOsArea, 'total')}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="12">
                <Card title="Mão de Obra">
                  <Tabs
                    defaultActiveKey="electrical"
                    id="uncontrolled-tab-example"
                  >
                    <Tab eventKey="electrical" title="Elétrica">
                      <Table
                        columns={collaboratorsColum}
                        data={collaborators.filter(
                          ({ specialty }) => specialty === 'Elétrica',
                        )}
                      />
                    </Tab>
                    <Tab eventKey="mechanics" title="Mecânica">
                      <Table
                        columns={collaboratorsColum}
                        data={collaborators.filter(
                          ({ specialty }) => specialty === 'Mecânica',
                        )}
                      />
                    </Tab>
                    <Tab eventKey="outsourced" title="Terceirizados">
                      <Table
                        columns={collaboratorsColum}
                        data={collaborators.filter(
                          ({ specialty }) => specialty === 'Terceirizados',
                        )}
                      />
                    </Tab>
                  </Tabs>
                </Card>
              </Col>
            </Row>

            <Row />

          {/* <Row>
              <Col sm="12" mdya="12">
                <ChartStackedBar
                  title="Os x Dia"
                  labels={_.pluck(dataOsWeekDay, 'date')}
                  data={[
                    {
                      label: 'Sistemática',
                      data: _.pluck(dataOsWeekDay, 'osSytematic'),
                    },
                    {
                      label: 'Não Sistemática',
                      data: _.pluck(dataOsWeekDay, 'osNoSytematic'),
                    },
                  ]}
                />
              </Col>
            </Row> */}
            {/* <Table c8olumns={tableColum} data={tableData} filter="name" /> */}

            {console.log({ oss, dataOsWeekDay, collaborators })}
          </>
        )}
      </Container>
    </>
  );
};

export default Programming;
