import React, { useEffect, useState } from 'react';

import _ from 'underscore';

import * as xlsx from 'node-xlsx';

import {
  Row,
  Col,
  Title,
  Container,
  FileUpload,
  InputUpload,
  ChartDoughnut,
  ChartBar,
  ChartStackedBar,
  Card,
  Table,
  Badge,
} from './styles';

import { HeaderEhs as Header } from '../../components/Headers';

import excelToData from './excelToData';
import {
  sagsInterface,
  chartCountInterface,
  sagWeekTypeInterface,
  sagWeekTypeDatasetInterface,
  actionsInterface,
} from './interfaces';

const Sag: React.FC = () => {
  const [sags, setSags] = useState<sagsInterface[]>([]);
  const [sagsOpen, setSagsOpen] = useState<sagsInterface[]>([]);
  const [totalNoAction, setTotalNoAction] = useState(0);
  const [totalDelay, setTotalDelay] = useState(0);
  const [dataStatus, setDataStatus] = useState<chartCountInterface[]>([]);
  const [dataLocationQuantity, setDataLocationQuantity] = useState<
    chartCountInterface[]
  >([]);
  const [dataTopIssuer, setDataTopIssuer] = useState<chartCountInterface[]>([]);
  const [dataOpenRiskRating, setDataOpenRiskRating] = useState<
    chartCountInterface[]
  >([]);
  const [dataCloseRiskRating, setDataCloseRiskRating] = useState<
    chartCountInterface[]
  >([]);
  const [dataSagWeekDayType, setDataSagWeekDayType] = useState<
    sagWeekTypeInterface[]
  >([]);
  const [dataSagType, setDataSagType] = useState<chartCountInterface[]>([]);
  const [dataSagWeekDayTypeDataset, setDataSagWeekDayTypeDataset] = useState<
    sagWeekTypeDatasetInterface[]
  >([]);

  const Issuer = [
    {
      dataField: 'name',
      text: 'Nome',
      sort: true,
    },
    {
      dataField: 'total',
      text: 'Quantidade de OPAs',
      sort: true,
    },
  ];

  const sagColum = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
    },
    {
      dataField: 'local',
      text: 'Local',
      sort: true,
    },
    {
      dataField: 'type',
      text: 'Tipo',
      sort: true,
    },
    {
      dataField: 'situation',
      text: 'Situação',
      sort: true,
    },
    // {
    //   dataField: 'date_occurrence',
    //   text: 'Data da Ocorrencia',
    //   sort: true,
    //   // formatter: cell => <li>{cell.toLocalString}</li>,
    // },
    {
      dataField: 'issuer.name',
      text: 'Solicitante',
      sort: true,
    },
    {
      dataField: 'actions',
      text: 'Ações',
      sort: true,
      formatter: (actions: actionsInterface[]) =>
        actions.map(action => <p>{action.description}</p>),
    },

    {
      dataField: 'actions',
      text: 'Previsão',
      sort: true,
      formatter: (actions: actionsInterface[]) =>
        actions.map(
        ({ date_deadline }) =>
            date_deadline && <p>{date_deadline.toLocaleDateString('pt-br')}</p>,
      ),
    },
    {
      dataField: 'actions',
      text: 'Status',
      sort: true,
      formatter: (actions: actionsInterface[]) =>
        actions.map(({ date_conclusion, date_deadline, situation }) => (
        <p>
            <Badge
              variant={
                situation === 'Cancelada'
                  ? 'dark'
                  : situation === 'Concluída'
                  ? 'success'
                  : !date_conclusion &&
                    date_deadline &&
                    date_deadline < new Date()
                  ? 'danger'
                  : 'primary'
              }
            >
              {
                (
                  !date_conclusion
                  && situation !== 'Cancelada'
                  && date_deadline
                  && date_deadline < new Date()
                )
                  ? 'Atrasada'
                  : situation
                  }
            </Badge>
            </p>
      )),
    },
    // {
    //   dataField: 'actions',
    //   text: 'Dias em atraso',
    //   sort: true,
    //   formatter: (actions: actionsInterface[]) => actions.map(({ date_conclusion, date_deadline }) => !date_conclusion && date_deadline && date_deadline < new Date() ? (
    //         <p>{date_conclusion}</p>
    //       ) : (
    //         ''
    //       ),),
    // },
  ];

  const getSagStatus = (): void => {
    const count: chartCountInterface[] = [];

    sags.map(({ situation }) => {
      const index = count.findIndex(
        (obj: chartCountInterface) => obj.name === situation,
      );
      if (index < 0) {
        count.push({
          name: situation,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    return setDataStatus(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total > b.total) return 1;
        return 0;
      }),
    );
  };

  const getSagType = (): void => {
    const count: chartCountInterface[] = [];

    sags.map(({ type }) => {
      const index = count.findIndex(
        (obj: chartCountInterface) => obj.name === type,
      );
      if (index < 0) {
        count.push({
          name: type,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    return setDataSagType(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total > b.total) return 1;
        return 0;
      }),
    );
  };

  const getOpenSag = (): void => {
    const open: sagsInterface[] = [];

    sags.map((sag) => {
      if (sag.situation === 'ABERTA') {
        return open.push({ ...sag });
      }
    });

    return setSagsOpen(open);
  };

  const getLocationQuantity = (): void => {
    const count: chartCountInterface[] = [];

    sags.map(({ local }) => {
      const index = count.findIndex(
        (obj: chartCountInterface) => obj.name === local,
      );

      if (index < 0) {
        count.push({
          name: local,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });
    return setDataLocationQuantity(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total > b.total) return 1;
        return 0;
      }),
    );
  };

  const getTopIssuer = (): void => {
    const count: chartCountInterface[] = [];

    sags.map(({ issuer: { name } }) => {
      const index = count.findIndex(
        (obj: chartCountInterface) => obj.name === name,
      );

      if (index < 0) {
        count.push({
          name,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });
    return setDataTopIssuer(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total > b.total) return 1;
        return 0;
      }),
    );
  };

  const getRiskRating = (): void => {
    const countOpen: chartCountInterface[] = [];
    const countClose: chartCountInterface[] = [];

    sags.map(({ situation, risk: { classification } }) => {
      if (situation === 'ENCERRADA') {
        const index = countClose.findIndex(
          (obj: chartCountInterface) => obj.name === classification,
        );

        if (index < 0) {
          countClose.push({
            name: classification,
            total: 1,
          });
        } else {
          countClose[index].total += 1;
        }
      } else {
        const index = countOpen.findIndex(
          (obj: chartCountInterface) => obj.name === classification,
        );

        if (index < 0) {
          countOpen.push({
            name: classification,
            total: 1,
          });
        } else {
          countOpen[index].total += 1;
        }
      }
    });

    setDataOpenRiskRating(
      countOpen.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total > b.total) return 1;
        return 0;
      }),
    );
    setDataCloseRiskRating(
      countClose.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total > b.total) return 1;
        return 0;
      }),
    );
  };

  const getSagByWeekType = (): void => {
    const sagByWeek: sagWeekTypeInterface[] = [];

    sags.map(({ type, date_occurrence }) => {
      const index = sagByWeek.findIndex(
        (eos: sagWeekTypeInterface) =>
          eos.date === date_occurrence.toLocaleDateString('pt-br'),
      );

      if (index < 0) {
        sagByWeek.push({
          date: date_occurrence.toLocaleDateString('pt-br'),
          types: [
            {
              name: type,
              total: 1,
            },
          ],
        });
      } else {
        const indexType = sagByWeek[index].types.findIndex(
          (eos: chartCountInterface) => eos.name === type,
        );

        if (indexType < 0) {
          sagByWeek[index].types.push({
            name: type,
            total: 1,
          });
        } else {
          sagByWeek[index].types[indexType].total += 1;
        }
      }
    });

    return setDataSagWeekDayType(sagByWeek);
  };

  const getTotalDelays = (): void => {
    const count: Array<number> = [];

    sags.map(({ type, actions }) => {
      actions.map(({ situation, date_conclusion, date_deadline }) =>
        situation !== 'Cancelada'
        && !date_conclusion
        && date_deadline
        && date_deadline < new Date()
        ? count.push(1)
        : null,);
    });

    return setTotalDelay(count.reduce((t, n) => t + n, 0));
  };

  const getTotalNoAction = (): void => {
    const total = sags.reduce(
      (t: number, { situation, actions }) => situation === 'ABERTA' && actions && !actions[0].description
            ? t + 1
          : t,
      0,
    );

    return setTotalNoAction(total);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.files;

    if (newValue && newValue?.length >= 0) {
      const reader: FileReader = new FileReader();

      reader.onload = (): void => {
        if (reader && reader.result) {
          const arrayBuffer: string | ArrayBuffer = reader.result;

          const source = xlsx.parse(arrayBuffer, {
            type: 'array',
            cellDates: true,
            cellNF: false,
            cellText: false,
          });

          const database = source[0].data;

          database.shift();

          return setSags(excelToData(database));
        }
      };

      if (!newValue.length) {
        setSags([]);

        return;
      }

      reader.readAsArrayBuffer(newValue[0]);
    }
  };

  const getSagByWeekTypeDataset = (): void => {
    const quantUnsafeCondition: Array<number> = [];
    const quantMaterialsDamage: Array<number> = [];
    const quantAlmosteAccidente: Array<number> = [];
    const quantInsecureAct: Array<number> = [];

    dataSagWeekDayType.map(({ types }) => {
      const indexUnsafeCondition = types.findIndex(
        (obj: chartCountInterface) => obj.name === 'CONDIÇÃO INSEGURA',
      );

      indexUnsafeCondition >= 0
        ? quantUnsafeCondition.push(types[indexUnsafeCondition].total)
        : quantUnsafeCondition.push(0);

      const indexMaterialsDamage = types.findIndex(
        (obj: chartCountInterface) => obj.name === 'DANOS MATERIAIS',
      );

      indexMaterialsDamage >= 0
        ? quantMaterialsDamage.push(types[indexMaterialsDamage].total)
        : quantMaterialsDamage.push(0);

      const indexAlmosteAccidente = types.findIndex(
        (obj: chartCountInterface) => obj.name === 'QUASE ACIDENTE (TF4)',
      );

      indexAlmosteAccidente >= 0
        ? quantAlmosteAccidente.push(types[indexAlmosteAccidente].total)
        : quantAlmosteAccidente.push(0);

      const indexInsecureAct = types.findIndex(
        (obj: chartCountInterface) => obj.name === 'ATO INSEGURO',
      );

      indexInsecureAct >= 0
        ? quantInsecureAct.push(types[indexInsecureAct].total)
        : quantInsecureAct.push(0);

      return setDataSagWeekDayTypeDataset([
        {
          label: 'Condição Insegura',
          data: quantUnsafeCondition,
        },
        {
          label: 'Danos Materiais',
          data: quantMaterialsDamage,
        },
        {
          label: 'Quase Acidente',
          data: quantAlmosteAccidente,
        },
        {
          label: 'Ato Insegura',
          data: quantInsecureAct,
        },
      ]);
    });
  };

  useEffect(() => {
    if (sags.length > 0) {
      getTotalDelays();
      getTotalNoAction();
      getSagStatus();
      getSagByWeekType();
      getLocationQuantity();
      getTopIssuer();
      getRiskRating();
      getOpenSag();
      getSagType();
    }
  }, [sags]);

  useEffect(() => {
    if (dataSagWeekDayType.length > 0) {
      console.log('getSagByWeekTypeDataset');
      getSagByWeekTypeDataset();
    }
  }, [dataSagWeekDayType]);

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col sm="12" md="12">
            <Title>SAG</Title>
            <FileUpload>
              <InputUpload
                type="file"
                placeholder="Upload"
                // accept=".xlsx"
                onChange={onChange}
              />
            </FileUpload>
          </Col>
        </Row>

        {sags.length > 0 && (
          <>
            <Row>
              <Col sm="12" md="2">
                <Card title="OPAs! sem Ações">
                  <h1>{Math.round(totalNoAction)} Opas</h1>
                </Card>
                <br />
                <Card title="Ações em Atraso">
                  <h1>{Math.round(totalDelay)} Ações</h1>
                </Card>
              </Col>
              <Col sm="12" md="5">
                <ChartBar
                  dataLabel="Total"
                  title="OPAs Status"
                  labels={_.pluck(dataStatus, 'name')}
                  data={_.pluck(dataStatus, 'total')}
                />
              </Col>
              <Col sm="12" md="5">
                <ChartBar
                  dataLabel="Total"
                  title="Tipo de Relato"
                  labels={_.pluck(dataSagType, 'name')}
                  data={_.pluck(dataSagType, 'total')}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="6">
                <ChartDoughnut
                  title="Classificação OPAs - Abertas"
                  labels={_.pluck(dataOpenRiskRating, 'name')}
                  data={_.pluck(dataOpenRiskRating, 'total')}
                />
                <br />
                <ChartDoughnut
                  title="Classificação OPAs - Fechadas"
                  labels={_.pluck(dataCloseRiskRating, 'name')}
                  data={_.pluck(dataCloseRiskRating, 'total')}
                />
              </Col>
              <Col sm="12" md="6">
                <Card title="Top Emitente">
                  <Table columns={Issuer} data={dataTopIssuer} />
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="12">
                <ChartBar
                  dataLabel="Total"
                  title="Quantidade x Local"
                  labels={_.pluck(dataLocationQuantity, 'name')}
                  data={_.pluck(dataLocationQuantity, 'total')}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" mdya="12">
                <Card title="OPAs">
                  <Table columns={sagColum} data={sagsOpen} />
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm="12" mdya="12">
                <ChartStackedBar
                  title="Tipo de Relato x Dia"
                  labels={_.pluck(dataSagWeekDayType, 'date')}
                  data={dataSagWeekDayTypeDataset}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default Sag;
