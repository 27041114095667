import React, { useEffect, useState } from 'react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import _ from 'underscore';

import * as xlsx from 'node-xlsx';

import {
  Row,
  Col,
  Title,
  Container,
  FileUpload,
  InputUpload,
  Buttons,
  Li,
  ChartDoughnut,
  ChartBar,
  ChartStackedBar,
  Card,
  Table,
  Tab,
  Tabs,
} from './styles';

import { HeaderMaintenance as Header } from '../../components/Headers';

import excelToData from './excelToData';
import { osInterface, chartCountInterface } from './interfaces';

const Planning: React.FC = () => {
  const [oss, setOss] = useState<osInterface[]>([]);
  const [totalDelay, setTotalDelay] = useState(0);
  const [dataOsSystematic, setDataOsSystematic] = useState<
    chartCountInterface[]
  >([]);
  const [dataOsDelaySystematic, setDataOsDelaySystematic] = useState<
    chartCountInterface[]
  >([]);
  const [dataOsSpeciality, setDataOsSpeciality] = useState<
    chartCountInterface[]
  >([]);
  const [dataOsImpediment, setDataOsImpediment] = useState<
    chartCountInterface[]
  >([]);
  const [dataOsArea, setDataOsArea] = useState<chartCountInterface[]>([]);
  const ossColum = [
    {
      dataField: 'id',
      text: 'OS',
      sort: true,
      formatter: (cell: any) => (
        <a
          href={
          `http://placofs.simmais.com.br/Form2Frames.aspx?pagBase=CadastroOS&Tipo=F2&OS=${cell}&Divisao=1&Titulo=Abertura%20de%20OS&Tipo=F2`}
          target="_blank"
        >
          {cell}
        </a>
      ),
    },
    {
      dataField: 'description',
      text: 'Descrição',
      sort: true,
    },
    {
      dataField: 'place',
      text: 'Área',
      sort: true,
    },
    {
      dataField: 'equipment',
      text: 'Equipamento',
      sort: true,
    },
    {
      dataField: 'systematic',
      text: 'Sistemática',
      sort: true,
      formatter: (cell: any) => (cell === true ? 'Sim' : 'Não'),
    },
    {
      dataField: 'specialty',
      text: 'Especialidade',
      sort: true,
    },
    {
      dataField: 'planning_date',
      text: 'Data de Planejamento',
      sort: true,
      formatter: (cell: any) => cell.toLocaleDateString('pt-br'),
    },
    {
      dataField: 'impediment',
      text: 'Impedimento',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'planning_date',
      text: 'Atraso',
      sort: true,
      formatter: (cell: Date) => cell < new Date() ? formatDistanceToNow(cell, { locale: ptBR }) : '',
    },
  ];

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.files;

    if (newValue && newValue?.length >= 0) {
      const reader: FileReader = new FileReader();

      reader.onload = (): void => {
        if (reader && reader.result) {
          const arrayBuffer: string | ArrayBuffer = reader.result;

          const source = xlsx.parse(arrayBuffer, {
            type: 'array',
            cellDates: true,
            cellNF: false,
            cellText: false,
          });

          const database = source[0].data;

          database.shift();

          return setOss(excelToData(database));
        }
      };

      if (!newValue.length) {
        setOss([]);

        return;
      }

      reader.readAsArrayBuffer(newValue[0]);
    }
  };

  const getOsImpediment = (): void => {
    const count: chartCountInterface[] = [];

    oss.map(({ impediment }) => {
      if (!impediment) return;

      const index = count.findIndex(
        (eos: chartCountInterface) => eos.name === impediment,
      );

      if (index < 0) {
        count.push({
          name: impediment,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    return setDataOsImpediment(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      }),
    );
  };

  const getOsSpecialty = (): void => {
    const count: chartCountInterface[] = [];

    oss.map(({ specialty }) => {
      const index = count.findIndex(
        (eos: chartCountInterface) => eos.name === specialty,
      );

      if (index < 0) {
        count.push({
          name: specialty,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    return setDataOsSpeciality(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      }),
    );
  };

  const getTotalDelays = (): void => {
    const total = oss.reduce((t, { delay }) => (delay ? t + 1 : t), 0);

    return setTotalDelay(total);
  };

  const getSystematicCount = (): void => {
    const count: chartCountInterface[] = [];

    oss.map(({ systematic }) => {
      const index = count.findIndex(
        (obj: chartCountInterface) => obj.name === (systematic ? 'Sistemática' : 'Não Sistemática'),
      );

      if (index < 0) {
        count.push({
          name: systematic ? 'Sistemática' : 'Não Sistemática',
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    return setDataOsSystematic(count);
  };

  const getSystematicDelayCount = (): void => {
    const count: chartCountInterface[] = [];

    oss.map(({ delay,systematic }) => {
      if(delay) {

        const index = count.findIndex(
          (obj: chartCountInterface) => obj.name === (systematic ? 'Sistemática' : 'Não Sistemática'),
        );

        if (index < 0) {
          count.push({
            name: systematic ? 'Sistemática' : 'Não Sistemática',
            total: 1,
          });
        } else {
          count[index].total += 1;
        }
    }

    });

    return setDataOsDelaySystematic(count);
  };

  const getAreaCount = (): void => {
    const count: chartCountInterface[] = [];

    oss.map(({ area }) => {
      const index = count.findIndex(
        (eos: chartCountInterface) => eos.name === area,
      );

      if (index < 0) {
        count.push({
          name: area,
          total: 1,
        });
      } else {
        count[index].total += 1;
      }
    });

    return setDataOsArea(
      count.sort((a, b) => {
        if (a.total > b.total) return -1;
        if (a.total < b.total) return 1;
        return 0;
      }),
    );
  };

  useEffect(() => {
    if (oss.length > 0) {
      getTotalDelays();
      getAreaCount();
      getSystematicCount();
      getOsSpecialty();
      getOsImpediment();
      getSystematicDelayCount();
    }
  }, [oss]);

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col sm="12" md="12">
            <Title>Planejamento</Title>
            <FileUpload>
              <InputUpload
                type="file"
                placeholder="Upload"
                accept=".xlsx"
                onChange={onChange}
              />
            </FileUpload>
          </Col>
        </Row>

        {oss.length !== 0 && (
          <>
            {console.log({ oss, totalDelay })}
            <Row>
              <Col sm="12" md="6">
              <Row>
                <Col sm="12" md="12">
                  <ChartBar
                    dataLabel="Total"
                    title="Sistemática X Não Sistemática"
                    labels={_.pluck(dataOsSystematic, 'name')}
                    data={_.pluck(dataOsSystematic, 'total')}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="4">
                  <Card title="OS em Atraso">
                    <h1>{Math.round(totalDelay)}</h1>
                  </Card>
                </Col>
                <Col sm="12" md="8">
                  <ChartBar
                    dataLabel="Total"
                    title="Tipo em Atraso"
                    labels={_.pluck(dataOsDelaySystematic, 'name')}
                    data={_.pluck(dataOsDelaySystematic, 'total')}
                  />
                </Col>

              </Row>
              </Col>


              <Col sm="12" md="6">
                <ChartBar
                  dataLabel="Total"
                  title="Impedimento"
                  labels={_.pluck(dataOsImpediment, 'name')}
                  data={_.pluck(dataOsImpediment, 'total')}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="6">
                <ChartBar
                  dataLabel="Total"
                  title="OS x Área"
                  labels={_.pluck(dataOsArea, 'name')}
                  data={_.pluck(dataOsArea, 'total')}
                />
              </Col>
              <Col sm="12" md="6">
                <ChartDoughnut
                  title="OS X Especialidade"
                  labels={_.pluck(dataOsSpeciality, 'name')}
                  data={_.pluck(dataOsSpeciality, 'total')}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="12">
                <Card title="OS">
                  <Table columns={ossColum} data={oss} />
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default Planning;
