import { osInterface } from './interfaces';

import {
  getLocationDefinition,
  getServiceType,
  getSpecialty,
} from '../../utils';

function excelToData(data: any[]): any {
  const orderServices: Array<osInterface> = [];

  data.map((value: any[]): void => {
    const indexOs = orderServices.findIndex(
      (eos: osInterface) => eos.id === value[0],
    );

    if (indexOs < 0) {
      orderServices.push({
        id: value[0],
        description: value[1],
        place: value[2],
        area: getLocationDefinition(value[2]),
        equipment: value[3],
        specialty: getSpecialty(value[4]),
        systematic: value[5] === 'Sim',
        type: getServiceType(value[6]),
        responsible: value[7],
        changedBy: value[8],
        impediment: value[10],
        planning_date: value[9],
        delay: value[9] < new Date(),
      });
    }
  });

  return orderServices;
}

export default excelToData;
