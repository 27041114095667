import React from 'react';
import PropTypes from 'prop-types';

import { Table } from './styles';

type dataColum = {
  dataField: string;
  text: string;
  sort?: boolean;
};

type TableDefaultProps = {
  columns: Array<dataColum>;
  data: Array<any>;
};

const TableDefault: React.FC<TableDefaultProps> = ({ columns, data }) => (
  <Table keyField="id" data={data} columns={columns} />
);

TableDefault.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default TableDefault;
