import { sagsInterface } from './interfaces';

function excelToData(data: any[]): any {
  const sags: Array<sagsInterface> = [];

  data.map((value: any[]): void => {
    const indexOs = sags.findIndex((eos: sagsInterface) => eos.id === value[0]);

    if (indexOs < 0) {
      sags.push({
        id: value[0],
        unit: value[1],
        origin: value[2],
        local: value[6],
        type: value[3],
        date_occurrence: value[7],
        date_registration: value[8],
        risk: {
          classification: value[4],
          risk: value[5],
        },
        issuer: {
          name: value[9],
          id: value[10],
          sgid: value[11],
        },
        description: value[12],
        situation: value[14],
        step: value[15],
        immediate_action: value[13],
        actions: [
          {
            responsible_treatment: value[16],
            description: value[17],
            responsible: value[18],
            date_deadline: value[19],
            date_conclusion: value[20],
            situation: value[21],
          },
        ],
      });
    } else {
      sags[indexOs].actions.push({
        responsible_treatment: value[16],
        description: value[17],
        responsible: value[18],
        date_conclusion: value[20],
        date_deadline: value[19],
        situation: value[21],
      });
    }
  });

  return sags;
}

export default excelToData;
