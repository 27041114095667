import _ from 'underscore';
import { movimentationsInterface } from './interfaces';

import { invertDate } from '../../utils';

function excelToData(data: any[]): any {
  const movimentations: Array<movimentationsInterface> = [];

  data.map((value: any[]): void => {
    movimentations.push({
      date: _.isDate(value[7])
        ? new Date(value[7])
        : new Date(invertDate(value[7])),
      well1: value[12],
      well2: value[13],
      well3: value[14],
      tank: value[15],
    });
  });

  return movimentations.sort((a, b) => {
    if (new Date(a.date).getTime() < new Date(b.date).getTime()) return -1;
    if (new Date(a.date).getTime() > new Date(b.date).getTime()) return 1;
    return 0;
  });
}

export default excelToData;
