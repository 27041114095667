import React, { useEffect, useState } from 'react';

import * as xlsx from 'node-xlsx';

import {
  Row,
  Col,
  Title,
  Container,
  FileUpload,
  InputUpload,
  Card,
} from './styles';

import { HeaderEhs as Header } from '../../components/Headers';

import excelToData from './excelToData';
import { movimentationsInterface, wellInterface } from './interfaces';

const Well: React.FC = () => {
  const [wells, setWells] = useState<movimentationsInterface[]>([]);
  const [reservoirs, setReservoirs] = useState<wellInterface[]>([]);
  const [totalWell1, setTotalWell1] = useState(0);
  const [totalWell2, setTotalWell2] = useState(0);
  const [totalWell3, setTotalWell3] = useState(0);
  const [totalTank, setTotalTank] = useState(0);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.files;

    if (newValue && newValue?.length >= 0) {
      const reader: FileReader = new FileReader();

      reader.onload = (): void => {
        if (reader && reader.result) {
          const arrayBuffer: string | ArrayBuffer = reader.result;

          const source = xlsx.parse(arrayBuffer, {
            type: 'array',
            cellDates: true,
            cellNF: false,
            cellText: false,
          });

          const database = source[0].data;

          database.shift();
          database.shift();

          return setWells(excelToData(database));
        }
      };

      if (!newValue.length) {
        setWells([]);

        return;
      }

      reader.readAsArrayBuffer(newValue[0]);
    }
  };

  const checkList = () => {
    const reservoirsList: wellInterface[] = [
      {
        name: 'Poço 1',
        lastCount: 0,
        reading: [],
      },
      {
        name: 'Poço 2',
        lastCount: 0,
        reading: [],
      },
      {
        name: 'Poço 3',
        lastCount: 0,
        reading: [],
      },
      {
        name: 'Tanque Industrial',
        lastCount: 0,
        reading: [],
      },
    ];

    wells.map(({ date, well1, well2, well3, tank
}) => {
      reservoirsList[0].reading.push({
        date,
        read: well1,
        consumption: well1 - reservoirsList[0].lastCount
      });
      reservoirsList[0].lastCount = well1;

      reservoirsList[1].reading.push({
        date,
        read: well2,
        consumption: well2 - reservoirsList[1].lastCount
      });
      reservoirsList[1].lastCount = well2;

      reservoirsList[2].reading.push({
        date,
        read: well3,
        consumption: well3 - reservoirsList[2].lastCount
      });
      reservoirsList[2].lastCount = well3;

      reservoirsList[3].reading.push({
        date,
        read: tank,
        consumption: tank - reservoirsList[3].lastCount
      });
      reservoirsList[3].lastCount = tank;
    });

    return setReservoirs(reservoirsList);
  };

  const getTotalWell1 = (): void => {
    const total = reservoirs[0].reading.reduce(
      (t: number, { read }) => t + read,
      0,
    );

    return setTotalWell1(total);
  };

  const getTotalWell2 = (): void => {
    const total = reservoirs[1].reading.reduce(
      (t: number, { read }) => t + read,
      0,
    );

    return setTotalWell2(total);
  };

  const getTotalWell3 = (): void => {
    const total = reservoirs[2].reading.reduce(
      (t: number, { read }) => t + read,
      0,
    );

    return setTotalWell3(total);
  };

  const getTotalTank = (): void => {
    const total = reservoirs[3].reading.reduce(
      (t: number, { read }) => t + read,
      0,
    );

    return setTotalTank(total);
  };

  useEffect(() => {
    if (wells.length > 0) {
      checkList();
    }
  }, [wells]);

  useEffect(() => {
    if (reservoirs.length > 0) {
      getTotalWell1();
      getTotalWell2();
      getTotalWell3();
      getTotalTank();
    }
  }, [reservoirs]);

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col sm="12" md="12">
            <Title>Poços</Title>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="12">
            <FileUpload>
              <InputUpload
                type="file"
                placeholder="Upload"
                accept=".csv"
                onChange={onChange}
              />
            </FileUpload>
          </Col>
        </Row>

        {wells.length !== 0 && (
          <Row>
            {console.log({wells,reservoirs})}
            <Col sm="12" md="3">
              <Card title="Consumo - Poço 1">
                <h1>{Math.round(totalWell1)}l</h1>
              </Card>
            </Col>
            <Col sm="12" md="3">
              <Card title="Consumo - Poço 2">
                <h1>{Math.round(totalWell2)}l</h1>
              </Card>
            </Col>
            <Col sm="12" md="3">
              <Card title="Consumo - Poço 3">
                <h1>{Math.round(totalWell3)}l</h1>
              </Card>
            </Col>
            <Col sm="12" md="3">
              <Card title="Consumo - Tanque Industrial">
                <h1>{Math.round(totalTank)}l</h1>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Well;
