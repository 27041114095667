function getSpecialty(specialty: string): string {
  switch (specialty) {
    case 'MANUTENÇÃO ELÉTRICA':
      return 'Elétrica';
    case 'MANUTENÇÃO DE AUTOMAÇÃO':
      return 'Automação';
    case 'MANUTENÇÃO DE REFRIGERACAO':
      return 'Refrigeração';
    case 'MANUTENÇÃO MECÂNICA':
      return 'Mecânica';
    case 'LUBRIFICAÇÃO':
      return 'Lubrificação';
    case 'USINAGEM/CALDERARIA':
      return 'Unsinagem/Calderaria';
    case 'MANUTENÇÃO UTILIDADES':
      return 'Utilidades';
    case 'TERCEIRIZADO':
      return 'Terceirizados';
    default:
      return 'Outros';
  }
}
export default getSpecialty;
