import {
  osInterface,
  osExternalInterface,
  actionInterface,
  techActionInterface,
  actionExternalInterface,
} from './interfaces';

import {
  getLocationDefinition,
  getServiceType,
  getSpecialty,
  getDateDifference,
  getWeekDay,
} from '../../utils';

function excelToData(data: any[]): any {
  const orderServicesProg: Array<osInterface> = [];
  const orderServicesExternal: Array<osExternalInterface> = [];

  data.map((value: any[]): void => {
    if (!value[2] && !value[3] && !value[4]) {
      const indexOs = orderServicesExternal.findIndex(
        (eos: osExternalInterface) => eos.id === value[0],
      );

      if (indexOs < 0) {
        orderServicesExternal.push({
          id: value[0],
          description: value[1],
          place: value[5],
          area: getLocationDefinition(value[5]),
          equipment: value[6],
          specialty: getSpecialty(value[7]),
          systematic: value[8] === 'Sim',
          type: getServiceType(value[9]),
          changedBy: value[17],
          responsible: value[16],
          actions: [
            {
              date_closing: value[13],
              date_appointment_start: value[14],
              date_appointment_end: value[15],
            },
          ],
        });
      } else {
        const isEqual = orderServicesExternal[indexOs].actions.findIndex(
          (eos: actionExternalInterface) =>
            eos.date_appointment_start === value[14],
        );

        if (isEqual < 0) {
          orderServicesExternal[indexOs].actions.push({
            date_closing: value[13],
            date_appointment_start: value[14],
            date_appointment_end: value[15],
          });
        }
      }
    } else {
      const indexOs = orderServicesProg.findIndex(
        (eos: osInterface) => eos.id === value[0],
      );

      if (indexOs < 0) {
        orderServicesProg.push({
          id: value[0],
          description: value[1],
          place: value[5],
          area: getLocationDefinition(value[5]),
          equipment: value[6],
          specialty: getSpecialty(value[7]),
          systematic: value[8] === 'Sim',
          type: getServiceType(value[9]),
          mdo: {
            programming: 1,
          },
          hh: {
            programming: getDateDifference(value[4], value[3]),
          },
          responsible: value[16],
          changedBy: value[17],
          actions: [
            {
              date: new Date(value[3]).toLocaleDateString('pt-br'),
              weekday: getWeekDay(new Date(value[3]).getDay()),
              programming: [
                {
                  technician: value[2],
                  duration: getDateDifference(value[4], value[3]),
                  start: new Date(value[3]),
                  done: new Date(value[4]),
                },
              ],
              appointment: value[14] ? [
                {
                  technician: value[2],
                  duration: getDateDifference(value[15], value[14]),
                  start: new Date(value[14]),
                  done: new Date(value[15]),
                },
              ] : [],
              date_done: new Date(value[13])
            },
          ],
        });
      } else {
        const isRepeated = orderServicesProg[indexOs].actions.map(
          ({ programming }) => {
            if (programming) {
              const start_hour = new Date(value[3]).toLocaleTimeString('it-IT');
              const start_date = new Date(value[3]).toLocaleDateString('pt-br');

              const indexTechAction = programming.findIndex(
                ({ start, technician }: techActionInterface) =>
                  new Date(start).toLocaleDateString('pt-br') === start_date
                  && new Date(start).toLocaleTimeString('it-IT') === start_hour
                  && technician === value[2],
              );

              return !(indexTechAction < 0);
            }
          },
        );

        if (isRepeated) return;

        orderServicesProg[indexOs].mdo.programming += 1;
        orderServicesProg[indexOs].hh.programming += getDateDifference(
          value[4],
          value[3],
        );

        const date = new Date(value[3]).toLocaleDateString('pt-br');

        const indexAction = orderServicesProg[indexOs].actions.findIndex(
          (eos: actionInterface) => eos.date === date,
        );

        if (indexAction >= 0) {
          orderServicesProg[indexOs].actions[indexAction].programming.push({
            technician: value[2],
            duration: getDateDifference(value[4], value[3]),
            start: new Date(value[3]),
            done: new Date(value[4]),
          });

          orderServicesProg[indexOs].actions[indexAction].date_done = new Date(value[14])

          orderServicesProg[indexOs].actions[indexAction].appointment.push({
              technician: value[2],
              duration: getDateDifference(value[15], value[14]),
              start: new Date(value[14]),
              done: new Date(value[15]),
          })

        } else {
          orderServicesProg[indexOs].actions.push({
            date: new Date(value[3]).toLocaleDateString('pt-br'),
            weekday: getWeekDay(new Date(value[3]).getDay()),
            programming: [
              {
                technician: value[2],
                duration: getDateDifference(value[4], value[3]),
                start: new Date(value[3]),
                done: new Date(value[4]),
              },
            ],
            appointment: [
              {
                technician: value[2],
                duration: getDateDifference(value[15], value[14]),
                start: new Date(value[14]),
                done: new Date(value[15]),
              },
            ],
            date_done: new Date(value[13])
          });
        }
      }
    }
  });

  return { orderServicesProg, orderServicesExternal };
}

export default excelToData;
