import React from 'react';

import Logo from '../../../assets/img/logo.png';
import LogoSTG from '../../../assets/img/logo-st-gobain.png';

import { Navbar, Brand, Link, Collapse } from './styles';

const HeaderEhs: React.FC = () => (
  <Navbar>
    <Brand href="/">
      <img
        src={Logo}
        width="180"
        height="140"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
      />
    </Brand>
    <Brand href="/">
      <img
        src={LogoSTG}
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
      />
    </Brand>
    <Collapse className="justify-content-end">
      <Link href="/sag">SAG</Link>
      <Link href="/well">Poços</Link>
    </Collapse>
  </Navbar>
);

export default HeaderEhs;
