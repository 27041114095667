import {
  osInterface,
  actionInterface,
  techActionInterface,
} from './interfaces';

import {
  getLocationDefinition,
  getServiceType,
  getSpecialty,
  getDateDifference,
  getWeekDay,
} from '../../utils';

function excelToData(data: any[]): any {
  const orderServices: Array<osInterface> = [];

  data.map((value: any[]): void => {
    if (!value[2] && !value[3] && !value[4]) return;

    const indexOs = orderServices.findIndex(
      (eos: osInterface) => eos.id === value[0],
    );

    if (indexOs < 0) {
      orderServices.push({
        id: value[0],
        description: value[1],
        place: value[5],
        area: getLocationDefinition(value[5]),
        equipment: value[6],
        specialty: getSpecialty(value[7]),
        systematic: value[8] === 'Sim',
        type: getServiceType(value[9]),
        mdo: {
          programming: 1,
        },
        hh: {
          programming: getDateDifference(value[4], value[3]),
        },
        responsible: value[16],
        changedBy: value[17],
        actions: [
          {
            date: new Date(value[3]).toLocaleDateString('pt-br'),
            weekday: getWeekDay(new Date(value[3]).getDay()),
            programming: [
              {
                technician: value[2],
                duration: getDateDifference(value[4], value[3]),
                start: {
                  date: new Date(value[3]).toLocaleDateString('pt-br'),
                  hour: new Date(value[3]).toLocaleTimeString('it-IT'),
                },
                done: {
                  date: new Date(value[4]).toLocaleDateString('pt-br'),
                  hour: new Date(value[4]).toLocaleTimeString('it-IT'),
                },
              },
            ],
          },
        ],
      });
    } else {
      const isRepeated = orderServices[indexOs].actions.map(
        ({ programming }) => {
          const start_hour = new Date(value[3]).toLocaleTimeString('it-IT');
          const start_date = new Date(value[3]).toLocaleDateString('pt-br');

          const indexTechAction = programming.findIndex(
            ({ start: { date, hour }, technician }: techActionInterface) =>
              date === start_date
              && hour === start_hour &&
              technician === value[2],
          );

          return !(indexTechAction < 0);
        },
      );

      if (isRepeated) return;

      orderServices[indexOs].mdo.programming += 1;
      orderServices[indexOs].hh.programming += getDateDifference(
        value[4],
        value[3],
      );

      const date = new Date(value[3]).toLocaleDateString('pt-br');

      const indexAction = orderServices[indexOs].actions.findIndex(
        (eos: actionInterface) => eos.date === date,
      );

      if (indexAction >= 0) {
        orderServices[indexOs].actions[indexAction].programming.push({
          technician: value[2],
          duration: getDateDifference(value[4], value[3]),
          start: {
            date: new Date(value[3]).toLocaleDateString('pt-br'),
            hour: new Date(value[3]).toLocaleTimeString('it-IT'),
          },
          done: {
            date: new Date(value[4]).toLocaleDateString('pt-br'),
            hour: new Date(value[4]).toLocaleTimeString('it-IT'),
          },
        });
      } else {
        orderServices[indexOs].actions.push({
          date: new Date(value[3]).toLocaleDateString('pt-br'),
          weekday: getWeekDay(new Date(value[3]).getDay()),
          programming: [
            {
              technician: value[2],
              duration: getDateDifference(value[4], value[3]),
              start: {
                date: new Date(value[3]).toLocaleDateString('pt-br'),
                hour: new Date(value[3]).toLocaleTimeString('it-IT'),
              },
              done: {
                date: new Date(value[4]).toLocaleDateString('pt-br'),
                hour: new Date(value[4]).toLocaleTimeString('it-IT'),
              },
            },
          ],
        });
      }
    }
  });

  return orderServices;
}

export default excelToData;
