import styled from 'styled-components';
import { Card as CardTemplate } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';

export const Card = styled(CardTemplate)`
  border-color: #0569c8;
`;

export const Chart = styled(Doughnut)``;

export const Header = styled(Card.Header)`
  color: #fff;
  background-color: #0569c8;
`;
