import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import randomcolor from 'randomcolor';

import { Card, Chart, Header } from './styled';

type ChartBarStackedDefaultProps = {
  title: string;
  dataLabel: string;
  labels: Array<string>;
  data: Array<number>;
};

const options = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
      },
    ],
  },
};

const ChartBarStackedDefault: React.FC<ChartBarStackedDefaultProps> = ({
  title,
  dataLabel,
  labels,
  data,
}) => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    updateDataValues();
  }, [labels, data]);

  const updateDataValues = (): void => {
    const Colors = data.map(item => randomcolor());

    return setChartData({
      labels,
      datasets: [
        {
          label: dataLabel,
          data,
          backgroundColor: Colors,
          borderColor: Colors,
          borderWidth: 1,
        },
      ],
    });
  };

  return (
    <Card>
      <Header as="h5">{title}</Header>
      <Card.Body>
        <Chart data={chartData} options={options} />
      </Card.Body>
    </Card>
  );
};

ChartBarStackedDefault.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  dataLabel: PropTypes.string.isRequired,
};

export default ChartBarStackedDefault;
