function getLocationDefinition(location: string): string {
  switch (location) {
    case 'PREDIO LABORATORIO':
      return 'ADM';
    case 'PREDIO ADMINISTRATIVO':
      return 'ADM';
    case 'PREDIO MANUTENÇÃO':
      return 'ADM';

    case 'SISTEMA INFERIOR DE PAPEL':
      return 'Cartão';
    case 'SISTEMA SUPERIOR DE PAPEL':
      return 'Cartão';
    case 'CIRCUITO PAPEL':
      return 'Cartão';

    case 'SALA ELÉTRICA CALCINAÇÃO - ER1':
      return 'Utilidades';
    case 'SALA ELÉTRICA WET TRANSFER - ER3':
      return 'Utilidades';
    case 'SALA ELÉTRICA TAKE OFF- ER5':
      return 'Utilidades';
    case 'SUBESTAÇÃO 01 - SALA TRAFO #1':
      return 'Utilidades';
    case 'UTILIDADES 1':
      return 'Utilidades';
    case 'SUBESTAÇÃO 02 - SALA TRAFO #2':
      return 'Utilidades';
    case 'ÁGUA DE PROCESSO':
      return 'Utilidades';
    case 'SALA ELÉTRICA FORMULAÇÃO - ER2':
      return 'Utilidades';
    case 'SALAS ELETRICAS':
      return 'Utilidades';
    case 'OFICINA DE MANUTENÇÃO':
      return 'Utilidades';
    case 'SALA ELÉTRICA SECADOR - ER4':
      return 'Utilidades';
    case 'CABINE PRIMÁRIA':
      return 'Utilidades';

    case 'ZONA 1 SECADOR':
      return 'Secador';
    case 'PRÉ ZONA SECADOR':
      return 'Secador';
    case 'ZONA 2 SECADOR':
      return 'Secador';
    case 'TROCADOR DE CALOR - ZONA 3':
      return 'Secador';
    case 'ENTRADA DO SECADOR - ZONA 33':
      return 'Secador';
    case 'TENSÃO SECADOR':
      return 'Secador';
    case 'LUBRIFICAÇÃO CORRENTE':
      return 'Secador';
    case 'ZONA 3 SECADOR':
      return 'Secador';
    case 'SECADOR':
      return 'Secador';
    case 'Zona 2':
      return 'Secador';
    case 'Zona 1':
      return 'Secador';
    case 'Zona 3':
      return 'Secador';

    case 'SISTEMA TRANSPORTE DE PEDRA':
      return 'Circuito Rocha';
    case 'SISTEMA DE SCRAP':
      return 'Circuito Rocha';
    case 'CIRCUITO RECICLAGEM':
      return 'Circuito Rocha';
    case 'CIRCUITO DE ROCHA':
      return 'Circuito Rocha';

    case 'SISTEMA DO MIXER':
      return 'Mixer';
    case 'MESA VIBRATÓRIA MIXER':
      return 'Mixer';
    case 'AREA DO MIXER':
      return 'Mixer';
    case 'Misturado e formado':
      return 'Mixer';

    case 'SISTEMA BMA':
      return 'Gessaria';
    case 'SISTEMA DE RECIRCULAÇÃO DO STUCCO':
      return 'Gessaria';
    case 'FIBRA DE VIDRO':
      return 'Gessaria';
    case 'FILTRO RESFRIADOR DE ESTUQUE':
      return 'Gessaria';
    case 'DESCARGA DO RESFRIADOR DE ESTUQUE':
      return 'Gessaria';
    case 'FILTRO GERAL DE ADITIVOS SÓLIDOS':
      return 'Gessaria';
    case 'GESSARIA PISO B':
      return 'Gessaria';
    case 'GESSARIA PISO F':
      return 'Gessaria';
    case 'GESSARIA PISO E':
      return 'Gessaria';
    case 'GESSARIA PISO A':
      return 'Gessaria';
    case 'GESSARIA PISO C':
      return 'Gessaria';
    case 'GESSARIA PISO D':
      return 'Gessaria';

    case 'SISTEMA DE COLA':
      return 'Aditivos Líquidos';
    case 'SISTEMA  ÁGUA':
      return 'Aditivos Líquidos';
    case 'SISTEMA RETARDANTE':
      return 'Aditivos Líquidos';
    case 'SISTEMA DISPERSANTE':
      return 'Aditivos Líquidos';
    case 'SISTEMA DE SILICONE':
      return 'Aditivos Líquidos';
    case 'SISTEMA DE ESPUMA':
      return 'Aditivos Líquidos';

    case 'ÁCIDO BÓRICO':
      return 'Aditivos Sólidos';
    case 'SISTEMA DE DEXTROSE':
      return 'Aditivos Sólidos';
    case 'SISTEMA DE AÇÚCAR':
      return 'Aditivos Sólidos';
    case 'SISTEMA DE CIMENTO':
      return 'Aditivos Sólidos';
    case 'SISTEMA DE AMIDO':
      return 'Aditivos Sólidos';
    case 'DOSADOR #1 DE ADITIVOS SECOS':
      return 'Aditivos Sólidos';

    case 'SISTEMA CALCINAÇÃO':
      return 'Calcinação';
    case 'MOINHO DE IMPACTO':
      return 'Calcinação';
    case 'RESFRIADOR DE ESTUQUE (COOLER)':
      return 'Calcinação';
    case 'PENEIRA ROTATIVA':
      return 'Calcinação';
    case 'ESTOQUE DE PEDRAS':
      return 'Estoque';

    case 'PLACO FEIRA DE SANTANA':
      return 'Externo';
    case 'SISTEMA DO FILTRO DE MANGAS DA SERRA':
      return 'Externo';
    case 'AREA EXTERNA':
      return 'Externo';
    case 'PREDIO REFEITORIO':
      return 'Externo';
    case 'ÁREA DA EXPEDIÇÃO':
      return 'Externo';

    case 'WRAPPING MACHINE':
      return 'WM';

    case 'ZONA 61':
      return 'TTS';
    case 'ZONA 62':
      return 'TTS';
    case 'ZONA 50':
      return 'TTS';
    case 'ZONA 60':
      return 'TTS';
    case 'SAÍDA DE SECADOR - TAKE OFF':
      return 'TTS';
    case 'GERAL':
      return 'Geral';
    case 'ESTRUTURAL':
      return 'Geral';

    case 'LINHA DE PRODUÇÃO':
      return 'Geral';

    case 'ÁREA DE TRANSFERÊNCIA ÚMIDA (WET TRANSFER)':
      return 'TTH';
    case 'TTH - ZONA 32':
      return 'TTH';
    case 'ENTRADA DO SECADOR - TTH':
      return 'TTH';

    case 'FORMAÇÃO#1':
      return 'Formação';

    case 'FORMAÇÃO#2':
      return 'Formação';

    case 'ZONA 40':
      return 'TTH/TTS (Equipamento)';
    case 'SETOR ADITIVOS':
      return 'Aditivos';

    case 'SISTEMA LP':
      return 'LP';
    default:
      return '';
  }
}
export default getLocationDefinition;
