function getCollaboratorSpecialty(specialty: string): string {
  switch (specialty) {
    case 'ALLESON SILVA SOUSA':
      return 'Elétrica';
    case 'AURINO ARAÚJO FILHO':
      return 'Elétrica';
    case 'JOSEMAR CONCEIÇÃO DOS SANTOS':
      return 'Elétrica';

    case 'MARCIO DOS SANTOS SILVA SOUZA':
      return 'Elétrica';
    case 'NATÁLIA AMORIM':
      return 'Elétrica';
    case 'ELIELSON BARBOSA':
      return 'Elétrica';
    case 'DINIS CAETANO':
      return 'Elétrica';

    case 'MARLON DA SILVA SANTOS':
      return 'Automação/Elétrica';

    case 'ANDERSON CARLOS CONCEIÇÃO ALVES':
      return 'Mecânica';
    case 'BRUNO NOGUEIRA':
      return 'Mecânica';
    case 'DANIEL ROCHA PEREIRA':
      return 'Mecânica';
    case 'DENIS ROBSON':
      return 'Mecânica';
    case 'IVONILDO REIS':
      return 'Mecânica';
    case 'JENIVALDO PINHO DOS SANTOS':
      return 'Mecânica';
    case 'MARCOS ATAIDE DE JESUS':
      return 'Mecânica';
    case 'MURILO DOS SANTOS NETO':
      return 'Mecânica';
    case 'WILHO MARCOS COSTA FERREIRA':
      return 'Mecânica';
    default:
      return 'Terceirizados';
  }
}
export default getCollaboratorSpecialty;
