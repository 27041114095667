import React from 'react';

import {
  Background,
  Container,
  Row,
  Col,
  Card,
  Icon,
  Title,
  Logo,
} from './styles';

import saintGobain from '../../assets/img/logo-st-gobain.png';
import placo from '../../assets/img/logo.png';

import helmet from '../../assets/img/menu/helmet.png';
import maintenance from '../../assets/img/menu/maintenance.png';
import routes from '../../assets/img/menu/routes.png';
import warehouse from '../../assets/img/menu/warehouse.png';
import edu from '../../assets/img/menu/edu.png';
import buy from '../../assets/img/menu/buy.png';

const Home: React.FC = () => (
  <Background className="text-center">
    <Container>
      <Row>
        <Col sm="12" md="12">
          <Logo src={placo} />
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col sm="12" md="4">
          <Card href="/planning">
            <Icon src={maintenance} />
            <Title>Manutenção</Title>
          </Card>
        </Col>

        <Col sm="12" md="4">
          <Card href="/warehouse">
            <Icon src={warehouse} />
            <Title>Almoxarifado</Title>
          </Card>
        </Col>

        <Col sm="12" md="4">
          <Card href="/sag">
            <Icon src={helmet} />
            <Title>EHS</Title>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col sm="12" md="4">
          <Card href="/">
            <Icon src={routes} />
            <Title>Rotas</Title>
          </Card>
        </Col>
        <Col sm="12" md="4">
          <Card href="/">
            <Icon src={buy} />
            <Title>Compras</Title>
          </Card>
        </Col>

        <Col sm="12" md="4">
          <Card href="/">
            <Icon src={edu} />
            <Title>Treinamentos</Title>
          </Card>
        </Col>
      </Row> */}
    </Container>
    <Container>
      <Row>
        <Col sm="12" md="12">
          <Logo src={saintGobain} />
        </Col>
      </Row>
    </Container>
  </Background>
);

export default Home;
