import styled from 'styled-components';
import {
  Container as ContainerTemplate,
  Col as ColTemplate,
  Row as RowTemplate,
  Nav,
} from 'react-bootstrap';

export const Background = styled.div``;

export const Container = styled(ContainerTemplate)`
  flex: 1;
`;

export const Row = styled(RowTemplate)``;

export const Col = styled(ColTemplate)``;

export const Card = styled(Nav.Link)`
  background-color: #0569c8;
  border-radius: 15px;
  margin: 30px;
  padding: 20px;

  :hover {
    opacity: 0.8;
  }
`;

export const Icon = styled.img`
  height: 64px;
`;

export const Title = styled.h5`
  color: #fff;
  margin: 10px;
`;

export const Logo = styled.img``;
