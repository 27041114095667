import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import randomColor from 'randomcolor';

import { Card, Chart, Header } from './styled';

type ChartDoughnutDefaultProps = {
  title: string;
  labels: Array<string>;
  data: Array<number>;
};

const defaultValues = [
  'rgba(12, 8, 9, 0.5)',
  'rgba(235, 54, 54, 0.5)',
  'rgba(255, 206, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(153, 102, 255, 0.5)',
  'rgba(255, 159, 64, 0.5)',
];



const ChartDoughnutDefault: React.FC<ChartDoughnutDefaultProps> = ({
  title,
  labels,
  data,
}) => {
  const [chartData, setChartData] = useState({});
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    getRandomColorList();
  }, [])


  const getRandomColorList = () => {
    const newColors: string[] = [];

    while (newColors.length <= 25) {
      newColors.push(randomColor());
    }

    return setColors(newColors)
  }

  useEffect(() => {
    setChartData({
      labels,
      datasets: [
        {
          data,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1,
        },
      ],
    });
  }, [labels, data]);

  return (
    <Card>
      <Header as="h5">{title}</Header>
      <Card.Body>
        <Chart data={chartData} />
      </Card.Body>
    </Card>
  );
};

ChartDoughnutDefault.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default ChartDoughnutDefault;
