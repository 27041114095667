import { stockInterface } from './interfaces';

function excelToData(data: any[]): any {
  const stockServices: Array<stockInterface> = [];
  data.map((value: any[]): void => {
    const indexOs = stockServices.findIndex(
      (estock: stockInterface) => estock.id === value[0],
    );

    if (value[3] && value[3].indexOf('GIPSITA') >= 0) return;
    if (value[3] && value[3].indexOf('PLACA GESSO') >= 0) return;

    if (indexOs < 0) {
      stockServices.push({
        id: value[0],
        description: value[3],
        price: value[7] / value[6],
        document: value[2],
        measurement: value[5],
        amount: value[6],
        movimentations: [
          {
            date: value[8],
            amount: value[6],
            price: value[7],
            type: value[6] > 0 ? 'Entrada' : 'Saída',
          },
        ],
      });
    } else {
      stockServices[indexOs].amount += value[6];
      stockServices[indexOs].movimentations.push({
        date: value[8],
        amount: value[6],
        price: value[7],
        type: value[6] > 0 ? 'Entrada' : 'Saída',
      });
    }
  });

  return stockServices;
}

export default excelToData;
