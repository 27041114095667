import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import randomColor from 'randomcolor';

import { Card, Chart, Header } from './styled';

interface dataInterface {
  label: string;
  backgroundColor?: string;
  data: number[];
}

type ChartBarStackedDefaultProps = {
  title: string;
  labels: string[];
  data: dataInterface[];
};

const options = {
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
      },
    ],
  },
};



const ChartBarStackedDefault: React.FC<ChartBarStackedDefaultProps> = ({
  title,
  labels,
  data,
}) => {
  const [chartData, setChartData] = useState({});
  const [colors, setColors] = useState<string[]>([]);

  useEffect(() => {
    getRandomColorList();
  },[])


  const getRandomColorList = () => {
    const newColors: string[] = [];

    while (newColors.length <= 25) {
      newColors.push(randomColor());
    }

    return setColors(newColors);
  }


  const updateDataValues = (): void => {
    const datasets = data.map((item, index) => ({
      ...item,
      backgroundColor: item.backgroundColor || colors[index],
    }));

    return setChartData({
      labels,
      datasets,
    });
  };


  useEffect(() => {
    updateDataValues();
  }, [labels, data]);


  return (
    <Card>
      <Header as="h5">{title}</Header>
      <Card.Body>
        <Chart data={chartData} options={options} />
      </Card.Body>
    </Card>
  );
};

ChartBarStackedDefault.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default ChartBarStackedDefault;
