import React from 'react';
import PropTypes from 'prop-types';
import paginationFactory from 'react-bootstrap-table2-paginator';

import filterFactory from 'react-bootstrap-table2-filter';

import { Table } from './styles';

type dataColum = {
  dataField: string;
  text: string;
  sort?: boolean;
};

type TablePaginationProps = {
  columns: Array<dataColum>;
  data: Array<any>;
};

const TablePagination: React.FC<TablePaginationProps> = ({ columns, data }) => {
  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    showTotal: true,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '15',
        value: 20,
      },
    ],
  };

  return (
    <Table
      keyField="id"
      data={data}
      columns={columns}
      pagination={paginationFactory(options)}
      filter={filterFactory()}
    />
  );
};

TablePagination.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default TablePagination;
