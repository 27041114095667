import React, { useEffect, useState } from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';

import _ from 'underscore';

import * as xlsx from 'node-xlsx';

import {
  Row,
  Col,
  Title,
  Container,
  FileUpload,
  InputUpload,
  Table,
} from './styles';

import { HeaderWarehouse as Header } from '../../components/Headers';

import excelToData from './excelToData';
import { stockInterface, MovimentationInterface } from './interfaces';

const Warehouse: React.FC = () => {
  const [stocks, setStocks] = useState<stockInterface[]>([]);

  const stockColumns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      filter: textFilter(),
    },
    // {
    //   dataField: 'document',
    //   text: 'Documento',
    //   sort: true,
    //   filter: textFilter(),
    // },
    {
      dataField: 'description',
      text: 'Nome',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'amount',
      text: 'Quantidade',
      sort: true,
    },
    {
      dataField: 'measurement',
      text: 'UM',
      sort: true,
    },
    {
      dataField: 'movimentations',
      text: 'Última Movimentação',
      sort: true,
      formatter: (cell: MovimentationInterface[]) =>
        cell[0].date.toLocaleDateString('pt-br'),
    },
  ];

  const onChange = (
    uploadNumber: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const newValue = e.target.files;

    if (newValue && newValue?.length >= 0) {
      const reader: FileReader = new FileReader();

      reader.onload = (): void => {
        if (reader && reader.result) {
          const arrayBuffer: string | ArrayBuffer = reader.result;

          const source = xlsx.parse(arrayBuffer, {
            type: 'array',
            cellDates: true,
            cellNF: false,
            cellText: false,
          });

          const database = source[0].data;

          database.shift();

          return uploadNumber === 1
            ? setStocks(excelToData(database))
            : mergeAll(excelToData(database));
        }
      };

      if (!newValue.length) {
        setStocks([]);

        return;
      }

      reader.readAsArrayBuffer(newValue[0]);
    }
  };

  const mergeAll = (stockLists: stockInterface[]) => {
    const newList = [...stocks];
    stockLists.map((list) => {
      const indexOs = newList.findIndex(
        (estock: stockInterface) => estock.id === list.id,
      );

      if (indexOs < 0) {
        newList.push(list);
      } else {
        newList[indexOs].amount += list.amount;
        newList[indexOs].movimentations.push(...list.movimentations);
      }
    });
    return setStocks(
      newList.map((list) => {
        const movimentations = list.movimentations.sort((a, b) => {
          if (new Date(a.date).getTime() > new Date(b.date).getTime()) {
            return -1;
          }
          if (new Date(a.date).getTime() < new Date(b.date).getTime()) return 1;
          return 0;
        });
        return { ...list, movimentations };
      }),
    );
  };

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col sm="12" md="12">
            <Title>Almoxarifado</Title>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6">
            <FileUpload>
              <InputUpload
                type="file"
                placeholder="Upload"
                accept=".xlsx"
                onChange={(value) => onChange(1, value)}
              />
            </FileUpload>
          </Col>
          <Col sm="12" md="6">
            <FileUpload>
              <InputUpload
                type="file"
                placeholder="Upload"
                accept=".xlsx"
                onChange={(value) => onChange(2, value)}
              />
            </FileUpload>
          </Col>
        </Row>

        {stocks.length > 0 && (
          <Row>
            <Col md="12" sm="12">
              <Table columns={stockColumns} data={stocks} />
            </Col>
            {console.log(stocks)}
          </Row>
        )}
      </Container>
    </>
  );
};

export default Warehouse;
