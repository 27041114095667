const Colors = {
  primary: '#0569c8',
  secondary: '#5a5a5a',
  secondaryLight: '#dcdcdc',
  white: '#fff',
  gray: '#0F0',
  yellow: '#ff0',
  red: '#F00',
};

export default Colors;
